import { includes, orderBy } from "lodash"

export class RelatedPostsFactory {
  constructor(articles, currentArticleSlug) {
    this.articles = articles.filter(
      aArticle => aArticle.slug !== currentArticleSlug
    )

    this.currentArticleSlug = currentArticleSlug
    this.maxArticles = 3
    this.tags = []
  }

  // (4.) Builder pattern usage
  setMaxArticles(m) {
    this.maxArticles = m
    return this
  }

  setTags(tagsArray) {
    this.tags = tagsArray
    return this
  }

  getArticles() {
    const { tags, articles, maxArticles } = this
    // (5.) We use an Identity Map to keep track of score
    const identityMap = {}

    if (!!tags === false || tags.length === 0) {
      console.error("RelatedPostsFactory: Tags not provided, use setTags().")
      return []
    }

    function getSlug(article) {
      return article.slug
    }

    function addToMap(article) {
      const slug = getSlug(article)
      if (!identityMap.hasOwnProperty(slug)) {
        identityMap[slug] = {
          article: article,
          points: 0,
        }
      }
    }

    // (8.) For tags matches, we add 1 point
    function addTagsPoints(article, tags) {
      const tagPoint = 1
      const slug = getSlug(article)

      if (!article.tags) return

      article.tags.forEach(aTag => {
        if (includes(tags, aTag)) {
          identityMap[slug].points += tagPoint
        }
      })
    }

    function getIdentityMapAsArray() {
      return Object.keys(identityMap).map(slug => identityMap[slug])
    }

    for (let article of articles) {
      addToMap(article)
      addTagsPoints(article, tags)
    }

    const arrayIdentityMap = getIdentityMapAsArray()

    const similarArticles = orderBy(arrayIdentityMap, ["points"], ["desc"])

    return similarArticles.splice(0, maxArticles).map(similarArticle => {
      return similarArticle.article
    })
  }
}
