import React from "react"
import PostTitle from "../components/PostTitle"
import Image from "../components/Image"
import RelatedPosts from "../components/RelatedPosts"
import styled from "@emotion/styled"

function PostSingle(props) {
  const { image, html, slug, tags, title } = props

  return (
    <>
      <PostUI>
        <PostTitle>
          <h1>{title}</h1>
        </PostTitle>
        <Image align="center" src={image} title={title} />

        <div dangerouslySetInnerHTML={{ __html: html }} />
      </PostUI>
      <RelatedPosts slug={slug} tags={tags} />
    </>
  )
}

export const PostUI = styled("div")`
  margin: 2em 0 4em;
`

export default PostSingle
