import React from "react"
import { graphql } from "gatsby"
import { pick } from "lodash"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PostSingle from "../components/PostSingle"

function BlogPostTemplate(props) {
  const { description, location, siteTitle, title } = props

  const postProps = pick(props, [
    "date",
    "image",
    "html",
    "slug",
    "tags",
    "title",
  ])

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={title} description={description} />
      <PostSingle {...postProps} />
    </Layout>
  )
}

function Container(props) {
  const {
    data: { markdownRemark, site },
    location,
  } = props

  const post = markdownRemark
  const siteTitle = site.siteMetadata.title

  const { excerpt, html } = post
  const { image, title, description, date, slug, tags } = post.frontmatter

  const componentProps = {
    date,
    description: description || excerpt,
    html,
    image,
    location,
    post,
    siteTitle,
    slug,
    tags,
    title,
  }

  return <BlogPostTemplate {...componentProps} />
}

export default Container

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        slug
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
