import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Image from "../Image"

import { RelatedPostsFactory } from "./RelatedPostsFactory"
import styled from "@emotion/styled"

function RelatedPosts(props) {
  const { posts } = props

  if (!posts.length) return null

  return (
    <SectionUI>
      <HeaderUI>
        <h3>More DrawDraws</h3>
      </HeaderUI>
      <ListUI>
        {posts.map(post => (
          <ItemUI key={post.slug}>
            <Link to={post.slug}>
              <Image src={post.image} title={post.title} />
            </Link>
          </ItemUI>
        ))}
      </ListUI>
    </SectionUI>
  )
}

export const SectionUI = styled("section")`
  padding-bottom: 2em;
`

export const HeaderUI = styled("header")`
  padding: 0 0 15px;
  * {
    margin: 0;
    font-size: 1.2rem;
  }
`

export const ListUI = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  width: 100%;
`
export const ItemUI = styled("div")`
  opacity: 1;
  padding: 0 15px;
  transition: opacity 100ms linear;
  width: calc(100% / 3);

  &:hover {
    opacity: 0.6;
  }
`

function getPostsFromQuery(queryPosts) {
  return queryPosts.posts.edges.map(post => {
    const {
      node: { fields, frontmatter },
    } = post

    return { ...fields, ...frontmatter }
  })
}

export default props => (
  <StaticQuery
    query={graphql`
      query RelatedPosts {
        posts: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 1000
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                description
                tags
                date(formatString: "MMMM DD, YYYY")
                image {
                  childImageSharp {
                    fluid(maxWidth: 700) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { tags, slug } = props

      const articles = getPostsFromQuery(data)

      const posts = new RelatedPostsFactory(articles, slug)
        .setMaxArticles(3)
        .setTags(tags)
        .getArticles()

      return <RelatedPosts posts={posts} />
    }}
  />
)
